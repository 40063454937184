<template>
  <base-page-layout title="Critérios de Segmento">
    <div class="mb-2 mr-4">
      <header-filter v-model="filters" :filters-to-show="['industry']" />
    </div>

    <div class="d-flex justify-end align-center mb-4">
      <mf-button color="primary" label="Cadastro" icon="add" @click="create" />
    </div>

    <mf-card-container v-model="search" placeholder="Busca" use-search title="Critérios" description="Lista de critérios criados">
      <v-data-table
        :loading="$apollo.queries.criteria.loading"
        :headers="headers"
        :items="criteria"
        :search="debounceSearch"
        :options.sync="options"
        :server-items-length="dataCount"
        :footer-props="{ itemsPerPageOptions: [10, 15, 20] }"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | date('dd/MM/yyyy HH:mm') }}
        </template>

        <template #item.name="{ item }">
          <span class="link" @click="edit(item)">{{ item.name }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu :close-on-content-click="true">
            <template #activator="{ on }">
              <v-btn class="ml-2" icon v-on="on">
                <mf-icon>more_vert</mf-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="edit(item)">
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="erase(item)">
                <v-list-item-title>Apagar</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </mf-card-container>
  </base-page-layout>
</template>

<script>
import { QUERY_LIST_INDUSTRY_SEGMENT_CRITERIA, MUTATION_DELETE_IND_SEGMENT_CRITERIA } from '@/modules/industry/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    HeaderFilter: () => import('@/modules/industry/components/Filters/HeaderFilter.vue')
  },
  data: () => ({
    search: '',
    debounceSearch: '',
    timer: null,
    dataCount: 1,
    options: {},
    criteria: [],
    filters: {
      industries: []
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Nome',
          value: 'name',
          sortable: true,
          filterable: true,
          width: '20%'
        },
        {
          text: 'Descrição',
          value: 'description',
          sortable: true,
          filterable: true,
          width: '25%'
        },
        {
          text: 'Indústria',
          value: 'industry.name',
          sortable: true,
          filterable: true
        },
        {
          text: 'Criado em',
          value: 'created_at',
          sortable: false
        },
        {
          text: 'Criado por',
          value: 'created_by_email',
          sortable: true,
          filterable: true
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '2%'
        }
      ]
    },
    selectedIndustryIds() {
      return this.filters.industries.map(industry => industry._id)
    }
  },
  apollo: {
    criteria: {
      query: QUERY_LIST_INDUSTRY_SEGMENT_CRITERIA,
      fetchPolicy: 'network-only',
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['accounts']
        }
      },
      variables() {
        return {
          input: {
            industry_ids: this.selectedIndustryIds
          },
          pagination: {
            page: this.options.page || 1,
            pageSize: this.options.itemsPerPage || 10,
            search: this.debounceSearch || null,
            sort: this.options.sortBy || '',
            sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
          }
        }
      },
      update(result) {
        this.dataCount = result.listIndustrySegmentCriteria.count
        return result.listIndustrySegmentCriteria.data
      }
    }
  },
  watch: {
    search(newValue, _) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.options.page = 1
        this.debounceSearch = newValue
      }, 300)
    },
    filters: {
      handler: function() {
        this.options.page = 1
        window.localStorage.setItem('filters-segment-criteria', JSON.stringify(this.filters))
      },
      deep: true
    }
  },
  beforeMount() {
    const savedFilters = window.localStorage.getItem('filters-segment-criteria')
    if (savedFilters) {
      const parsed = JSON.parse(savedFilters)
      this.filters.industries = parsed.industries ? parsed.industries : []
    }
  },
  methods: {
    create() {
      this.$router.push({ name: 'criar-criterio-segmento' })
    },
    edit(item) {
      this.$router.push({ name: 'editar-criterio-segmento', params: { id: item._id } })
    },
    async erase(item) {
      const confirm = await this.$confirm({
        title: 'Apagar critério',
        message: 'Deseja apagar o critério de segmento?',
        confirmText: 'Apagar',
        cancelText: 'Cancelar'
      })
        .then(() => true)
        .catch(() => false)
      if (!confirm) return

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_DELETE_IND_SEGMENT_CRITERIA,
          variables: {
            input: { _id: item._id }
          },
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })

        this.criteria = this.criteria.filter(p => p._id !== item._id)
        this.$snackbar({ message: 'Critério excluído com sucesso.', snackbarColor: 'success' })
      } catch (e) {
        this.$snackbar({ message: `Erro ao excluir: ${e}.`, snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
